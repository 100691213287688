import React from 'react'
import CookiePolicy from '../../../../components/Legal/Organisation/CookiePolicy'

const Page = ({ location }) => {
    return (
        <CookiePolicy
            language="is"
            location={location}
        />
    )
}

export default Page
